.viewProductsButton {
    border: 2px solid #0A66C2;
    border-radius: 8px;
    padding: 12px 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #0A66C2;
}

.boxShadow {
    box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.25);
}