.App {
    width: 100%;
    /* max-width: 1440px; */
    margin-left: auto;
    margin-right: auto;
}

.seperator {
    border: 1px solid #E4E7EF;
    width: 100%;
    height: 0px;
}

.text_4Line {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}



.textSemiBold {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #151515;
}

.textSemiBoldLarge {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.02em;
}

.textMedium {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #151515;
}

.textMediumBlue {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #0F40CA;
}

.textMediumGrey {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #666666;
}

.textMediumShort {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
}